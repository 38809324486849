.App {
  background-color: #262A3D;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* 
  =================
  =================
  Check
  =================
  =================
*/

.RewardTable,
.Web3Table {
  background-color: #6D7089;
  opacity: 0.9;
  margin-bottom: 3vh;
  width: 80vw;
  border-radius: 10px;
}

.StatusTable {
  background-color: transparent;
  opacity: 0.9;
  margin-bottom: 3vh;
  width: 80vw;
  border-radius: 10px;
}

/*  Common Area   */

.titlespan {
  flex: 1;
  background: #DE3897;
}


/*  First Three Table Area  */

.FirstThreeTable {
  background-color: #6D7089;
  opacity: 0.9;
  margin-bottom: 3vh;
  width: 80vw;
  border-radius: 10px;
}

.FirstThreeTable_Title {
  flex-direction: row;
  display: flex;
}

.FirstThreeTable_TitleContent {
  flex: 19;
}

.FirstThreeTable_Rule {
  flex: 5;
}

.FirstThreeTable_Content {
  width: 100%;
  align-content: center;
}

.FirstThreeTable_Picture {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.FirstThreeTable_FirstPlace {
  flex: 1;
  max-width: 30vw;
}

.FirstThreeTable_SecondPlace {
  flex: 1;
  max-width: 20vw;
  margin-left: 8vw;
}

.FirstThreeTable_ThridPlace {
  flex: 1;
}

#FirstPlaceImage {
  max-width: 30vw;
}

#SecondPlaceImage,
#ThirdPlaceImage {
  max-width: 20vw;
}


/* 
  =================
  =================
  Check
  =================
  =================
*/

.Modal {
  z-index: 1000;
}

/* 
  =================
  =================
  Check
  =================
  =================
*/

#BannerImage {
  position: relative;
  z-index: 15;
  width: 80vw;
  border-radius: 20px;
}

#LotteryImage {
  position: relative;
  z-index: 15;
  width: 80vw;
  border-radius: 20px;
  cursor: pointer;
}

/* 
  =================
  =================
  Check
  =================
  =================
*/

.App-header {
  margin-bottom: 20vh;
}

/* 
  =================
  =================
  Check
  =================
  =================
*/

button {
  cursor: pointer;
  border-radius: 20px;
}

#ConnectButton {
  flex: 1;
  position: absolute;
  margin-top: 30px;
  right: 5vw;
  background-color: transparent;
  color: white;
  height: 40px;
  margin-bottom: 30px;
  width: 8vw;
  border: 3px solid rgb(197, 13, 197);
}

#ClaimButton {
  flex: 1;
  position: absolute;
  margin-top: 30px;
  right: 15vw;
  background-color: transparent;
  color: white;
  height: 40px;
  margin-bottom: 30px;
  width: 8vw;
  border: 3px solid rgb(197, 13, 197);
  z-index: 100;
}

/* 
  =================
  =================
  Check
  =================
  =================
*/

#amount {
  border-radius: 10px;
  max-width: 60vw;
}

.GuessDiv,
.ButtonRow {
  display: flex;
  flex-direction: row;
  z-index: -1;
}

.GuessRight,
.ButtonRight {
  flex: 2;
  background-color: #6D7089;
  opacity: 0.9;
  margin-bottom: 3vh;
  width: 30vw;
  border-radius: 10px;
}


.btnLeftPrimary {
  height: 10vh;
  flex: 2;
  background-color: #411b57;
  color: white;
  opacity: 0.9;
  margin-bottom: 3vh;
  margin-right: 3vw;
  margin-left: 3vw;
  width: 30vw;
  border-radius: 10px;
}

.btnLeftOutline {
  /* background-color: #a76a99; */
  flex: 2;
  background-color: white;
  color: #411b57;
  opacity: 0.9;
  margin-bottom: 3vh;
  margin-right: 3vh;
  width: 30vw;
  border-radius: 10px;
}

.GuessLeft,
.ButtonLeft {
  flex: 2;
  background-color: #6D7089;
  opacity: 0.9;
  margin-bottom: 3vh;
  margin-right: 3vh;
  width: 30vw;
  border-radius: 10px;
}

.titlespan {
  width: 4px;
  height: 16px;
  background: #DE3897;
}

.GuessRight {
  margin-left: 3vw;
}

/*
  ==========================================================
  ==========================================================

                          FOR MODALS

  ==========================================================
  ==========================================================
*/

/* Modal */
.overlay {
  /* background-color: rgba(0, 0, 0, 0.5); */
  position: fixed;
  width: 100%;
  height: 100%;
}

.modalContainer {
  max-width: 300px;
  max-height: 800px;
  width: 100%;
  position: fixed;
  z-index: 45;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

#transparentWord {
  color: transparent;
}

.modalGameContainer {
  max-width: 300px;
  width: 100%;
  position: fixed;
  z-index: 45;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('./image/gameBG7.png');
  background-size: 100% 100%;
  display: flex;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

.modalRuleContainer {
  max-width: 500px;
  width: 100%;
  position: fixed;
  z-index: 45;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: 100% 100%;
  display: flex;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  overflow-y: scroll;
  overflow-x: scroll;
}


.modalRuleContainer {
  flex-direction: column;
  top: 0vh;
  left: 40vw;
  transform: none;
  width: 150vw;
  height: 100vh;
  background-size: 100% 100%;
  overflow-y: scroll;
  overflow-x: scroll;
}


#foxtsd {
  background-image: url('./image/20.png');
  background-size: 100% 100%;
}

#foxtls {
  background-image: url('./image/19.png');
  background-size: 100% 100%;
}

#foxsd {
  background-image: url('./image/22.png');
  background-size: 100% 100%;
}

#foxls {
  background-image: url('./image/21.png');
  background-size: 100% 100%;
}

.modalRight {
  width: 100%;
}

.closeBtn {
  color: rgb(155, 27, 27);
  position: fixed;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  padding: 1rem 2rem;
}

.btnContainer {
  display: flex;
  padding: 1rem 1rem;
  height : 100px
}

.GamebtnContainer {
  display: flex;
  padding: 1rem 1rem;
  height : 80px
}

.GamebtnContainer input {
  width: 100%;
  margin: 0rem .5rem .5rem .5rem;
  padding: 16px 0;
  /* border: none; */
  border: 1px solid #411b57;
  /* color: #ffffff; */
}

.btnContainer input {
  width: 100%;
  margin: .5rem;
  padding: 16px 0;
  /* border: none; */
  border: 1px solid #411b57;
  /* color: #ffffff; */
}

.content input {
  width: 70%;
}

.btnPrimary {
  background-color: #411b57;
  color: white;
  cursor: pointer;
}

label,
p {
  color: black;
}

.btnOutline {
  /* background-color: #a76a99; */
  background-color: white;
  color: #411b57;
  cursor: pointer;
}

.bold {
  font-weight: 600;
}


#vid, #winpic, #losepic {
  margin-left: 15vw;
  width: 50vw;
  position: fixed;
  z-index: 6000;
}

#winpic, #losepic {
  z-index: 6001;
}


@media screen and (max-width: 600px) {
  .btnLeftPrimary {
    height: 15vh;
  }

  .modalContainer {
    flex-direction: column;
    top: 20vh;
    left: 20vw;
    transform: none;
    width: 60vw;
    height: 60vh;
  }


  .modalGameContainer {
    flex-direction: column;
    top: 20vh;
    left: 20vw;
    transform: none;
    width: 60vw;
    height: 60vh;
    background-image: url('./image/gameBG7.png');
    background-size: 100% 100%;
  }

  .modalRuleContainer {
    flex-direction: column;
    left: 20vw;
    transform: none;
    width: 60vw;
    background-size: 100% 100%;
    overflow-y: scroll;
    overflow-x: scroll;
  }

  .closeBtn {
    position: absolute;
    top: 1vh;
    right: 5vw;
  }

  .heading {
    margin: 1rem;
  }

  #vid, #winpic, #losepic  {
    width: 80vw;
    height: fit-content;
    margin-top: 20vh;
    position: fixed;
    z-index: 6000;
    margin-left: 0vw;
  }
  #winpic, #losepic {
    z-index: 6001;
  }

  #ToggleButton,
  #ConnectButton,
  #ClaimButton {
    width: 25vw;
  }

  #foxtsd {
    background-image: url('./image/16.png');
    /* background-image: url('./image/FOXTDS.png'); */
    background-size: 100% 100%;
  }

  #foxtls {
    background-image: url('./image/15.png');
    /* background-image: url('./image/FOXTLS.png'); */
    background-size: 100% 100%;
  }

  #foxsd {
    background-image: url('./image/18.png');
    /* background-image: url('./image/FOXDS.png'); */
    background-size: 100% 100%;
  }

  #foxls {
    background-image: url('./image/17.png');
    /* background-image: url('./image/FOXLS.png'); */
    background-size: 100% 100%;
  }
}

@media screen and (max-width: 400px) {
  #ClaimButton {
    right: 15vw;
  }
}


.HistorySameRow {
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  margin-bottom: 3vh;
}

.HistorySameRow {
  border: 1px solid white;
  background-color: #6D7089;
}

.HistorySameRow0 {
  flex: 0.5;
  background: #DE3897;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.HistorySameRow5 {
  flex: 0.5;
  background: #DE3897;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.HistorySameRow1 {
  flex: 1
}

.HistorySameRow2 {
  flex: 3
}

.HistorySameRow3 {
  flex: 2
}

.HistorySameRow4 {
  flex: 2
}

h4 {
  color: black;
}

.GuessTitle {
  display: flex;
  flex-direction: row;
}

.GuessTitle_Left,
.GuessTitle_Title,
.GuessTitle_Rule {
  flex: 1;
}

.GuessTitle_Rule {
  cursor: pointer;
}

#rule {
  cursor: pointer;
}

#claimBtn {
  width: 50px;
  height: 20px;
}

.Rules,
#RuleDiv {
  color: black;
}

.BalanceDiv {
  display: flex;
  flex-direction: row;
  max-width: 80vw;
}

#balanceLeft,
#balanceRight {
  flex: 1;
}

li {
  cursor: pointer;
  list-style-type: square;
}

#btnTransparent {
  opacity: 0;
}

.Pool {
  position: relative;
  display: flex;
  flex-direction: row;
}

.Pool img{
  position: absolute;
  z-index: -1;
}

#PoolImage {
  position: relative;
  z-index: 15;
  width: 80vw;
  border-radius: 20px;
}

.words {
  position: absolute;
  top: 30%;
  left: 20%;
  transform: translate(-50%, -50%);
  color: rgb(206, 184, 60);
  font-weight: bold;
  font-size: 40px;
  z-index: 18;
}

.words2 {
  position: absolute;
  top: 30%;
  left: 70%;
  transform: translate(-50%, -50%);
  color: rgb(206, 184, 60);
  z-index: 18;
  font-size: 40px;
  font-weight: bold;
}

.options {
  max-width: 80vw;
}

@media screen and (max-width: 600px) {
  .words,
  .words2 {
    font-size: 20px;
  }
}